import React, { useEffect, useState, useRef } from "react";
import MetaData from "../component/layout/MetaData";
import detailevent from "../images/detailofspeciallevent.jpg";
import specialPeople from "../images/specialeventpeople.jpg";
import { useNavigate, useParams } from "react-router-dom";
import Moment from "react-moment";
import { useDispatch, useSelector } from "react-redux";
import {
  getShivratriConfig,
  updateTimeLine,
} from "../Allslices/EventConfigSlice";
import { getSingleEvent, joinEventUser } from "../Allslices/EventConfigSlice";
import Slider from "react-slick";
import OrderModal from "../Payments/OrderModal";
import { getmyOrder } from "../Allslices/orderSlice";
import Gallery from "react-photo-gallery";
import { Modal, ModalGateway } from "react-images";
import { getAllBlogs } from "../Allslices/blogSlice";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { getAllOccupationList } from "../Allslices/occupationSlice";
import useGeolocation from "../component/shared/UserGeolocation";
import Select from "react-select";
import {
  getCountryList,
  getStatelist,
  getCitylist,
} from "../Allslices/countrycitystateSlice";
import { createorder, updateOrderStatus } from "../Allslices/orderSlice";
import {
  SHOW_ERROR_NOTIFICATION,
  SHOW_SUCCESS_NOTIFICATION,
  SHOW_INFO_NOTIFICATION,
} from "../appUtils/appToast";
const Shivratri = () => {
  const { slug, scheduleslug } = useParams();
  const dispatch = useDispatch();

  const [modalVisible, setModalVisible] = useState(false);
  const { EventSingle_final, isLoading, timeLineData } = useSelector(
    (state) => state.Event_Config
  );
  const { blog } = useSelector((state) => state?.blog);
  const allEventBlogs = blog?.allEventBlogs;

  const { occupation: occupationList } = useSelector(
    (state) => state.occupationList
  );
  const { countrylist, statelist, citylist } = useSelector(
    (state) => state?.countrystatecity
  );
  const userexistingdata = EventSingle_final?.eventsSchedule[0]?.userData?.map(
    (element, scheduleIndex) => ({
      email: element?.email,
      phoneNo: element?.phoneNo,
    })
  );
  const { user, isAuthenticated } = useSelector((state) => state.auth);
  const [selectedImage, setSelectedImage] = useState("");
  const [largeImage, setLargeImage] = useState("");
  const [isGalleryModalOpen, setIsGalleryModalOpen] = useState(false);
  const [mobileNumber, setMobileNumber] = useState("");
  const [countryCode, setCountryCode] = useState("");
  const [currentPage, setCurrentPage] = useState(4);
  const [displayedIndex, setDisplayedIndex] = useState({ start: 2, end: 5 });
  const openGalleryModal = (selectedImg) => {
    setSelectedImage(selectedImg);
    setIsGalleryModalOpen(true);
  };
  const startdate = EventSingle_final?.eventsSchedule[0]?.start_Date;
  const enddate = EventSingle_final?.eventsSchedule[0]?.end_Date;

  const currentDate = new Date();
  const startDate = new Date(startdate);
  const formRef = useRef(null);
  const scrollToForm = () => {
    formRef.current.scrollIntoView({ behavior: "smooth" });
  };
  const closeGalleryModal = () => {
    setSelectedImage(null);
    setIsGalleryModalOpen(false);
  };

  const handleHoverThumbnail = (url) => {
    setLargeImage(url);
  };

  console.log("TimeLineData", timeLineData);
  const handlePrevious = () => {
    const newStart = Math.max(displayedIndex.start - 4, 1);
    const newEnd = newStart + 3;
    setDisplayedIndex({ start: newStart, end: newEnd });
    setCurrentPage(currentPage - 1);
  };

  const handleLoadMore = () => {
    const newStart = displayedIndex.end + 1;
    const newEnd = displayedIndex.end + 4;
    setDisplayedIndex({ start: newStart, end: newEnd });
    setCurrentPage(currentPage + 1);
  };

  useEffect(() => {
    if (
      EventSingle_final?.eventsgallerylist &&
      EventSingle_final?.eventsgallerylist.length > 0
    ) {
      setLargeImage(EventSingle_final?.eventsgallerylist[0]?.thumbnail_image);
    }
  }, [EventSingle_final]);

  const smallImages =
    EventSingle_final?.eventsgallerylist?.slice(
      displayedIndex.start - 1,
      displayedIndex.end
    ) || [];

  useEffect(() => {
    dispatch(getSingleEvent({ slug, scheduleslug }));
    dispatch(getShivratriConfig());
    dispatch(getCountryList());
    dispatch(getAllOccupationList());
  }, [dispatch, slug, scheduleslug]);

  useEffect(() => {
    dispatch(getAllBlogs({ forEvent: true, limit: 100000000000000 }));
  }, []);

  let sliderRef1 = useRef();
  const titlemaxLength = 50;

  const settings1 = {
    dots: true,
    infinite: true,
    autoscroll: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        },
      },
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        },
      },
    ],
  };

  const initialFormData = {
    name: "",
    email: "",
    phoneNo: "",
    countryCode: "",
    address: "",
    occupation: "",
    dob: "",
    pinCode: "",
    userid: "",
    country: "",
    state: "",
    city: "",
  };
  const [formData, setFormData] = useState(initialFormData);

  const [email, setEmail] = useState("");

  const eventparent = EventSingle_final?.slug;
  const [isFormValid, setIsFormValid] = useState(false);

  const handleInputChange = (event) => {
    const { name, value, type, checked, files } = event.target;
    const updatedValue =
      type === "checkbox" ? checked : type === "file" ? files[0] : value;

    if (name === "email") {
      setEmail(updatedValue);
    }
    const allfieldsValid = Object.values(updatedValue).every(
      (field) => field !== ""
    );
    setIsFormValid(allfieldsValid);
    setFormData({
      ...formData,
      [name]: updatedValue,
    });
  };
  const handleSelectChange = (e) => {
    const { name, value } = e;
    if (name == "country") {
      let countryId = value?.id;
      setFormData({
        ...formData,
        country: value,
        state: null,
        city: null,
      });
      dispatch(getStatelist({ countryId }));
    } else if (name == "state") {
      let countryidd = value?.country_id;
      let stateidd = value?.id;
      setFormData({
        ...formData,
        state: value,
        city: null,
      });
      dispatch(getCitylist({ countryidd, stateidd }));
    } else {
      setFormData({
        ...formData,
        [name]: value,
      });
    }

    const allfieldsValid = Object.values(value).every((field) => field !== "");

    setIsFormValid(allfieldsValid);
  };
  const handlePhoneChange = (value, data) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
    }));
    const number = value?.slice(data?.dialCode?.length);
    setMobileNumber(number);
    setCountryCode(`+${data?.dialCode}`);
  };
  useEffect(() => {
    if (user) {
      let userOccupation = "";
      if (occupationList && occupationList.length > 0) {
        userOccupation = occupationList?.find(
          (occ) => occ.name === user.occupation
        );
      }
      setFormData((prevFormData) => ({
        ...prevFormData,
        email: user?.email,

        name: user?.name,
        userid: user?._id,
        address:
          [user?.addressLine1, user?.addressLine2].filter(Boolean).join(", ") ||
          null,
        occupation: userOccupation ?? "",
        dob: user?.dob,
        country: user?.country,
        state: user?.state,
        city: user?.city,
        pinCode: user?.pincode,
      }));
      setMobileNumber(user.phone);
      setCountryCode(user.countryCode);
    }
    dispatch(getCountryList());
    let countryId = user?.country?.id;
    dispatch(getStatelist({ countryId }));
    let countryidd = user?.country?.id;
    let stateidd = user?.state?.id;
    dispatch(getCitylist({ countryidd, stateidd }));
  }, [user, dispatch]);

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const { orderAll, order } = useSelector((state) => state?.order);
  const [checkBuyStatus, setCheckBuyStatus] = useState();
  const [selectedImageIndex, setSelectedImageIndex] = useState(0);
  useEffect(() => {
    const purchaseStatus = orderAll.filter(
      (item) =>
        item?.orderItems?.product === EventSingle_final?.eventsSchedule[0]?._id
    );
    setCheckBuyStatus(purchaseStatus[0]?.paymentInfo?.status);
  }, [orderAll]);

  const orderType = "events";
  let eventPurchased = null;
  useEffect(() => {
    dispatch(getmyOrder());
  }, [dispatch]);

  for (const order of orderAll) {
    if (
      order?.orderItems?.product === EventSingle_final?.eventsSchedule[0]?._id
    ) {
      eventPurchased = order;
      break;
    }
  }

  const handleAuthentication = (e) => {
    e.preventDefault();
    if (!isAuthenticated) {
      Navigate("/login");
      SHOW_INFO_NOTIFICATION("Please login to join the event");
      return;
    }
    for (const key in formData) {
      if (!formData[key]) {
        setIsFormValid(false);
        break;
      }
    }

    setIsFormValid(true);
  };
  const handleSubmit = (e) => {
    e.preventDefault();

    if (!isAuthenticated) {
      Navigate("/login");
      SHOW_INFO_NOTIFICATION("Please login to join the event");

      return;
    }

    let isValid = true;
    for (const key in formData) {
      if (!formData[key]) {
        setIsFormValid(false);
        isValid = false;
        break;
      }
    }

    if (!validateEmail(formData.email)) {
      alert("Please enter a valid email address.");
      setIsFormValid(false);
      return;
    }

    setIsFormValid(true);

    const formDataToSend = {
      ...formData,
      occupation: formData.occupation.name,
      userid: user?._id,
      phoneNo: mobileNumber,
      countryCode: countryCode,
    };

    const scheduleid = EventSingle_final?.eventsSchedule[0]._id;
    const currencySymbol = isIndianUser || !userGeolocationCode ? "₹" : "$";

    const order = {
      orderItems: {
        name: EventSingle_final?.title,
        monthValidity: "",
        description: EventSingle_final?.eventsSchedule[0].shortdescription,

        price: 0,
        quantity: 1,
        image: EventSingle_final?.eventsSchedule[0]?.thumbnail_image,
        product: EventSingle_final?.eventsSchedule[0]?._id,
        slug: EventSingle_final?.eventsSchedule[0]?.scheduleslug,
        paidAmount: 0,
        appliedPromoCode: "",
        songParentId: "",
        eventParent: EventSingle_final?.slug,
      },

      userDetails: {
        ...formData,
        occupation: formData.occupation.name,
        userid: user?._id,
        phone: mobileNumber,
        countryCode: countryCode,
      },
      itemsPrice: 0,
      taxPrice: 0,
      shippingPrice: 0,

      totalPrice: 0,
      paymentInfo: {
        id: Math.random() * 100,
        status: true,
      },
      orderType: "events",
      currencyType: currencySymbol,
    };
    if (!EventSingle_final?.eventsSchedule[0]?.isPaid) {
      dispatch(joinEventUser({ joinUser: formDataToSend, scheduleid }))
        .then(() => {
          if (!EventSingle_final?.eventsSchedule[0]?.isPaid) {
            dispatch(createorder(order)).then((result) => {
              const orderid = result.payload?.order?._id;
              const orderStatus = "Confirm";
              dispatch(updateOrderStatus({ orderid, orderStatus }));
            });
          }

          dispatch(getSingleEvent({ slug, scheduleslug }));
          SHOW_SUCCESS_NOTIFICATION("Successfully submitted");
        })
        .catch((error) => {
          console.error("Error joining event:", error);
          SHOW_ERROR_NOTIFICATION("Failed to join event. Please try again.");
        });
    }
  };

  const userExists =
    userexistingdata &&
    userexistingdata?.length > 0 &&
    isAuthenticated &&
    userexistingdata.some((element) => {
      return (
        element?.email === user?.email || element?.phoneNo === user?.phoneNo
      );
    });
  const Navigate = useNavigate();
  const formDataToSendevent = {
    ...formData,
    occupation: formData.occupation?.name,
    userid: user?._id,
    phoneNo: mobileNumber,
    countryCode: countryCode,
  };
  const annualsingleEvents = (slug) => {
    Navigate(`/blog/${slug}`);
  };
  const maxLength = 100;
  const handlePrev = () => {
    setSelectedImageIndex((prevIndex) => prevIndex - 1);
    setSelectedImage(
      EventSingle_final?.eventsgallerylist[selectedImageIndex - 1]
        ?.thumbnail_image
    );
  };

  const handleNext = () => {
    setSelectedImageIndex((prevIndex) => prevIndex + 1);
    setSelectedImage(
      EventSingle_final?.eventsgallerylist[selectedImageIndex + 1]
        ?.thumbnail_image
    );
  };

  const [optionSelected, setOption] = useState("");

  const timeSelectChange = (event) => {
    const selectedValue = event.target.value;
    setOption(selectedValue);
    const selectedEvents = groupedTimeline[selectedValue];
    dispatch(updateTimeLine(selectedEvents));
  };

  useEffect(() => {
    if (EventSingle_final?.status === false) {
      Navigate("*");
    }
  }, [EventSingle_final, dispatch]);

  const sellingPriceINR =
    EventSingle_final?.eventsSchedule[0]?.selling_price_rupee;
  const mrpPriceINR = EventSingle_final?.eventsSchedule[0]?.mrp_price_rupee;
  const sellingPriceUSD =
    EventSingle_final?.eventsSchedule[0]?.selling_price_usd;
  const mrpPriceUSD = EventSingle_final?.eventsSchedule[0]?.mrp_price_usd;

  const userGeolocationCode = useGeolocation();

  const isIndianUser = userGeolocationCode === "IN";
  const mrpPrice = isIndianUser ? mrpPriceINR : mrpPriceUSD;
  const sellingPrice = isIndianUser ? sellingPriceINR : sellingPriceUSD;

  const renderPrice = () => {
    const currencySymbol = isIndianUser || !userGeolocationCode ? "₹" : "$";

    if (
      sellingPrice === mrpPrice &&
      EventSingle_final?.eventsSchedule[0]?.isPaid === true
    ) {
      return `BUY NOW (${currencySymbol}${sellingPrice})`;
    } else if (sellingPrice < mrpPrice) {
      return (
        <>
          BUY NOW{" "}
          <span style={{ textDecoration: "line-through", color: "white" }}>
            {currencySymbol}
            {mrpPrice}
          </span>{" "}
          ({currencySymbol}
          {sellingPrice}){" "}
        </>
      );
    } else {
      return `BUY NOW (${currencySymbol}${sellingPrice})`;
    }
  };

  const [groupedTimeline, setGroupedTimeLine] = useState([]);
  const [uniqueDates, setUniqueDates] = useState([]);

  console.log("The EventSingle_final", EventSingle_final);
  // useEffect(() => {

  //   console.log("timefinal", EventSingle_final?.eventsSchedule[0]?.timeline)
  //   const sortedTimeline = [
  //     ...(EventSingle_final?.eventsSchedule[0]?.timeline || []),
  //   ].sort((a, b) => new Date(a.date) - new Date(b.date));

  //   console.log("sortedTimeline", sortedTimeline);
  //   const groupedTimeline = sortedTimeline.reduce((acc, event) => {
  //     const date = event.date;
  //     if (!acc[date]) {
  //       acc[date] = [];
  //     }
  //     acc[date].push(event);
  //     return acc;
  //   }, {});
  //   setGroupedTimeLine(groupedTimeline);

  //   console.log("groupedTimeline", groupedTimeline)
  //   const uniqueDates = Object.keys(groupedTimeline);
  //   console.log("The uniqueDates", uniqueDates);
  //   let firstDate;
  //   if (uniqueDates.length > 0 && !optionSelected) {
  //     firstDate = uniqueDates[0];
  //     setOption(firstDate);
  //   }
  //   setUniqueDates(uniqueDates);
  //   console.log(groupedTimeline[firstDate],'groupedTimeline[firstDate]')
  //   dispatch(updateTimeLine(groupedTimeline[firstDate]));
  // }, [slug, scheduleslug, EventSingle_final]);

  useEffect(() => {

    const sortedTimeline = [
      ...(EventSingle_final?.eventsSchedule[0]?.timeline || []),
    ].sort((a, b) => new Date(a.date) - new Date(b.date));


    const groupedTimeline = sortedTimeline.reduce((acc, event) => {
      const date = event.date;
      if (!acc[date]) {
        acc[date] = [];
      }
      acc[date].push(event);
      return acc;
    }, {});

    setGroupedTimeLine(groupedTimeline);

    const uniqueDates = Object.keys(groupedTimeline);

    let firstDate;
    if (uniqueDates.length > 0) {
      firstDate = uniqueDates[0];
      setOption(firstDate);
    }
    setUniqueDates(uniqueDates);
    if (firstDate) {
      dispatch(updateTimeLine(groupedTimeline[firstDate]));
    } else {
      console.log("No valid firstDate found, skipping timeline update");
    }
  }, [slug, scheduleslug, EventSingle_final]);

  return (
    <>
      {/* Main section started */}
      <MetaData
        title={EventSingle_final?.meta_title ?? "Dr. Archika Didi"}
        description={EventSingle_final?.meta_description ?? "Dr. Archika Didi"}
        tags={EventSingle_final?.meta_tag ?? "Dr. Archika Didi"}
      />

      {modalVisible && (
        <div
          className="modal"
          tabIndex="-1"
          role="dialog"
          style={{
            display: "block",
            position: "fixed",
            top: "50px",
            left: "75%",
            maxWidth: "300px",
            zIndex: "1050",
          }}
        ></div>
      )}

      <section
        className="event_card "
        style={{ paddingTop: "130px", marginBottom: "40px" }}
      >
        <div className="colormixsky d-none d-lg-block"></div>
        <div className="container">
          <div className="row">
            <div className="col-lg-6 pb-3 col-xl-6 col-md-12 col-sm-12 pt-md-2 pt-sm-2 ">
              <h1 id="santa haedingfont" className="medium">
                <span
                  className="md-pt-6 medium"
                  style={{ fontFamily: "myFont" }}
                >
                  {EventSingle_final?.title}
                </span>
              </h1>

              <p
                className="pt-4 lato medium homebdes"
                style={{ fontSize: "20px" }}
                dangerouslySetInnerHTML={{
                  __html:
                    EventSingle_final?.eventsSchedule[0]?.shortdescription,
                }}
              ></p>

              <div>
                {!userExists && eventPurchased === null ? (
                  <button
                    type="button"
                    onClick={scrollToForm}
                    data-bs-target="#staticBackdrop"
                    className="btn btn-lg shadowbuttons text-white fw-bold formEventButton"
                  >
                    {EventSingle_final?.eventsSchedule[0]?.isPaid
                      ? sellingPrice >= 0
                        ? renderPrice()
                        : "GET (FREE)"
                      : "JOIN"}
                  </button>
                ) : (
                  <>
                    {startDate > currentDate ? (
                      <>
                        {EventSingle_final?.eventsSchedule[0]?.eventType ===
                        "Offline" ? (
                          EventSingle_final?.eventsSchedule[0]?.location
                        ) : (
                          <span
                            className="p-2 text-white fs-5"
                            style={{
                              background: "#b767a2",
                              borderRadius: "15px",
                            }}
                          >
                            Join Event On (
                            <Moment
                              className="fw-semibold"
                              format="DD MMM YYYY"
                              withTitle
                            >
                              {startdate}
                            </Moment>
                            )
                          </span>
                        )}
                      </>
                    ) : (
                      <div className="d-flex  mt-3">
                        {EventSingle_final?.eventsSchedule[0]?.eventType ===
                          "Online" &&
                          EventSingle_final?.eventsSchedule[0]?.isPaid &&
                          orderAll.map((item) => {
                            if (
                              item?.orderItems?.product ===
                              EventSingle_final?.eventsSchedule[0]?._id
                            ) {
                              return (
                                <button
                                  key={item._id}
                                  type="button"
                                  className="btn btn-lg fs-6   shadowbuttons text-white"
                                  style={{
                                    cursor: "pointer",
                                  }}
                                >
                                  <a
                                    href={
                                      EventSingle_final?.eventsSchedule[0]
                                        ?.liveUrl
                                    }
                                    target="_blank"
                                    style={{ color: "inherit" }}
                                  >
                                    JOIN NOW
                                  </a>
                                </button>
                              );
                            }
                          })}

                        {EventSingle_final?.eventsSchedule[0]?.eventType ===
                          "Online" &&
                          !EventSingle_final?.eventsSchedule[0]?.isPaid && (
                            <button
                              type="button"
                              className="btn btn-lg  shadowbuttons text-white"
                              style={{
                                color: "white",
                                cursor: "pointer",
                              }}
                            >
                              <a
                                href={
                                  EventSingle_final?.eventsSchedule[0]?.liveUrl
                                }
                                target="_blank"
                                style={{ color: "inherit" }}
                              >
                                JOIN NOW
                              </a>
                            </button>
                          )}

                        {EventSingle_final?.eventsSchedule[0]?.eventType ===
                          "Offline" &&
                          EventSingle_final?.eventsSchedule[0]?.isPaid &&
                          orderAll.map((item) => {
                            if (
                              item?.orderItems?.product ===
                              EventSingle_final?.eventsSchedule[0]?._id
                            ) {
                              return (
                                <p key={item._id}>
                                  Here is the Location:{" "}
                                  {
                                    EventSingle_final?.eventsSchedule[0]
                                      ?.location
                                  }
                                </p>
                              );
                            }
                          })}

                        {EventSingle_final?.eventsSchedule[0]?.eventType ===
                          "Offline" &&
                          !EventSingle_final?.eventsSchedule[0]?.isPaid && (
                            <p>
                              Here is the Location:{" "}
                              {EventSingle_final?.eventsSchedule[0]?.location}
                            </p>
                          )}
                      </div>
                    )}
                  </>
                )}
              </div>
            </div>
            <div className="col-lg-6 pb-3 col-xl-6 col-md-12 col-sm-12 pt-md-2 pt-sm-2 d-flex justify-content-center align-items-center">
              {EventSingle_final?.eventsSchedule[0]?.contentType === "video" ? (
                <iframe
                  src={EventSingle_final?.eventsSchedule[0]?.contentUrl}
                  title={EventSingle_final?.eventsSchedule[0]?.title}
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share "
                  allowFullScreen
                  className="singlepageiframeevent"
                  style={{
                    borderRadius: "20px",
                    boxShadow: " -2px 1px 26px -1px rgba(0,0,0,1)",
                  }}
                ></iframe>
              ) : (
                <img
                  src={EventSingle_final?.eventsSchedule[0]?.content_image}
                  alt="Dr. Archika Didi"
                />
              )}
            </div>
          </div>
        </div>
      </section>
      {uniqueDates && uniqueDates.length > 0 && (
        <div
          className="container-fluid text-center "
          style={{
            backgroundImage: `url(${detailevent})`,
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center center",
            height: "10%",
          }}
        >
          <div className="container bootdey py-5">
            <div className="row gutters">
              <div
                className="container-fluid text-center singleevntsres"
                style={{
                  backgroundImage: `url(${detailevent})`,
                  backgroundSize: "cover",
                  backgroundRepeat: "no-repeat",
                  backgroundPosition: "center center",
                  height: "10%",
                }}
              >
                <h4
                  className="pt-1 haedingfont"
                  style={{
                    fontFamily: "Playfair Display",
                    paddingTop: "40px",
                  }}
                >
                  Detail of Events
                </h4>

                <select
                  className="mb-3 me-3 text-white"
                  style={{
                    position: "absolute",
                    right: "5%",
                    padding: "10px",
                    borderRadius: "4px",
                    backgroundColor: "#b767a2",
                    cursor: "pointer",
                  }}
                  onChange={timeSelectChange}
                  value={optionSelected}
                >
                  {uniqueDates.length > 0 ? (
                    uniqueDates.map((date, index) => (
                      <option key={index} value={date}>
                        <Moment
                          className="fw-semibold"
                          format="DD MMM YYYY"
                          withTitle
                        >
                          {date}
                        </Moment>
                      </option>
                    ))
                  ) : (
                    <option value="">No Date available</option>
                  )}
                </select>

                <div className="container bootdey py-5 mt-5">
                  <div className="row gutters">
                    <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                      <div className="card-1">
                        <div className="card-body">
                          <div className="timeline d-flex flex-column flex-wrap">
                            {timeLineData?.map((event, index) => (
                              <div className="timeline-row" key={index}>
                                <>
                                  <div className="timeline-time">
                                    <h4>{event?.time}</h4>
                                  </div>
                                  <div
                                    className="eventtheamicon"
                                    style={{
                                      backgroundImage: `url(${EventSingle_final?.eventsSchedule[0]?.themeicon_image})`,
                                      backgroundSize: "cover",
                                      backgroundRepeat: "no-repeat",
                                      backgroundPosition: "center center",
                                      height: "214px",
                                      top: "-18px",
                                      width: "290px",
                                      position: "absolute",
                                      right: "0px",
                                    }}
                                  ></div>
                                  <div className="timeline-content">
                                    <h3 style={{ color: "black" }}>
                                      {event?.title}
                                    </h3>
                                    <p
                                      style={{
                                        fontSize: "19px",
                                        fontFamily: "lato",
                                      }}
                                    >
                                      {event?.description}
                                    </p>
                                  </div>
                                </>
                              </div>
                            ))}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      {!userExists && eventPurchased === null && (
        <section
          ref={formRef}
          className="container-fluid text-center singleevntsres"
        >
          <h1 className="pb-5 haedingfont" style={{ fontFamily: "myFont" }}>
            Join the Event
          </h1>
          <div></div>
          <div
            className="container-fluid text-center pt-5"
            style={{
              backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(${EventSingle_final?.eventsSchedule[0]?.bottombanner_image})`,

              backgroundSize: "cover",
              backgroundRepeat: "no-repeat",
              backgroundPosition: "center center",
              height: "100%",
            }}
          >
            <div className="container gallerysec py-5">
              <div className="">
                <form
                  style={{ width: "23rem" }}
                  onSubmit={handleSubmit}
                  id="formEvent"
                >
                  {/* Name input */}

                  <div
                    data-mdb-input-init
                    className="form-outline mb-2 position-relative d-flex align-items-start flex-column"
                    style={{
                      borderRadius: "50px",
                    }}
                  >
                    <label htmlFor="name" className="text-white">
                      Name :
                    </label>
                    <input
                      placeholder="Name"
                      defaultValue={formData.name}
                      style={{
                        background: "#F5F5F5",
                      }}
                      required
                      type="text"
                      name="name"
                      id="name"
                      className="form-control"
                      onChange={handleInputChange}
                    />{" "}
                    <span className="text-danger position-absolute top-50 end-0 mx-2">
                      *
                    </span>
                  </div>

                  {/* Email input */}

                  <div
                    data-mdb-input-init
                    className="form-outline mb-2 position-relative d-flex align-items-start flex-column"
                    style={{
                      boxShadow: "0px 0px 39px 0px #00000029",
                    }}
                  >
                    <label htmlFor="email" className="text-start text-white">
                      Email :
                    </label>
                    <input
                      placeholder="Email"
                      defaultValue={formData.email}
                      style={{
                        background: "#F5F5F5",
                      }}
                      required
                      type="email"
                      name="email"
                      id="email"
                      className="form-control"
                      onChange={handleInputChange}
                    />{" "}
                    <span className="text-danger position-absolute top-50 end-0 mx-2">
                      *
                    </span>
                    {email && !validateEmail(email) && (
                      <div style={{ color: "red" }}>
                        Please enter a valid email address
                      </div>
                    )}
                  </div>

                  {/* Phone No input */}
                  <div
                    data-mdb-input-init
                    className="form-outline mb-2 eventform position-relative  position-relative d-flex align-items-start flex-column"
                  >
                    <label htmlFor="phoneNo" className="text-start text-white">
                      PhoneNo :
                    </label>
                    <PhoneInput
                      country={"in"}
                      placeholder="Phone No:"
                      value={`${countryCode}+ " " +${mobileNumber}`}
                      type="number"
                      name="phoneNo"
                      countryCodeEditable={false}
                      style={{
                        background: "#F5F5F5",
                      }}
                      required
                      id="form4Example2"
                      className="form-control"
                      onChange={handlePhoneChange}
                    />{" "}
                    <span className="text-danger position-absolute top-50 end-0 mx-2">
                      *
                    </span>
                  </div>

                  <div className="form-outline mb-2 position-relative d-flex align-items-start flex-column  shivratricenter">
                    <label
                      htmlFor="occupation"
                      className="text-start text-white"
                    >
                      Occupation :
                    </label>
                    <Select
                      className="w-100"
                      options={occupationList}
                      getOptionLabel={(options) => {
                        return options["name"];
                      }}
                      getOptionValue={(options) => {
                        return options["name"];
                      }}
                      value={formData.occupation}
                      name="occupation"
                      placeholder="Occupation"
                      onChange={(e) =>
                        handleSelectChange({ value: e, name: "occupation" })
                      }
                    />

                    <span className="text-danger position-absolute top-50 end-0 mx-2">
                      *
                    </span>
                  </div>
                  <div
                    data-mdb-input-init
                    className="form-outline mb-2 position-relative d-flex align-items-start flex-column"
                  >
                    <label htmlFor="dob" className="text-start text-white">
                      D.O.B :
                    </label>
                    <input
                      placeholder="D.O.B"
                      name="dob"
                      type="date"
                      value={formData?.dob}
                      className="form-control"
                      id="dob"
                      onChange={handleInputChange}
                      style={{
                        background: "#F5F5F5",
                      }}
                      rows="4"
                    />
                    <span className="text-danger position-absolute top-50 end-0 mx-2">
                      *
                    </span>
                  </div>

                  {/* Address input */}
                  <div
                    data-mdb-input-init
                    className="form-outline mb-2 position-relative d-flex align-items-start flex-column"
                  >
                    <label htmlFor="address" className="text-start text-white">
                      Address :
                    </label>
                    <textarea
                      placeholder="Address"
                      name="address"
                      value={formData?.address}
                      className="form-control"
                      id="address"
                      onChange={handleInputChange}
                      required
                      style={{
                        background: "#F5F5F5",
                      }}
                      rows="4"
                    />{" "}
                    <span className="text-danger position-absolute top-50 end-0 mx-2">
                      *
                    </span>
                  </div>

                  <div className="form-outline mb-2 position-relative d-flex align-items-start flex-column  shivratricenter">
                    <label htmlFor="country" className="text-start text-white">
                      Country :
                    </label>
                    <Select
                      className="w-100"
                      options={countrylist}
                      getOptionLabel={(options) => {
                        return options["name"];
                      }}
                      getOptionValue={(options) => {
                        return options["name"];
                      }}
                      value={formData.country}
                      name="country"
                      placeholder="Country"
                      onChange={(e) =>
                        handleSelectChange({ value: e, name: "country" })
                      }
                    />

                    <span className="text-danger position-absolute top-50 end-0 mx-2">
                      *
                    </span>
                  </div>
                  <div className="mb-2 position-relative  position-relative d-flex align-items-start flex-column shivratricenter">
                    <label htmlFor="state" className="text-start text-white">
                      State :
                    </label>
                    <Select
                      className="w-100"
                      options={statelist}
                      getOptionLabel={(options) => {
                        return options["name"];
                      }}
                      getOptionValue={(options) => {
                        return options["name"];
                      }}
                      value={formData.state}
                      name="state"
                      placeholder="State"
                      onChange={(e) =>
                        handleSelectChange({ value: e, name: "state" })
                      }
                    />

                    <span className="text-danger position-absolute top-50 end-0 mx-2">
                      *
                    </span>
                  </div>
                  <div className="mb-2 position-relative position-relative d-flex align-items-start flex-column shivratricenter">
                    <label htmlFor="city" className="text-start text-white">
                      City :
                    </label>
                    <Select
                      className="w-100"
                      options={citylist}
                      getOptionLabel={(options) => {
                        return options["name"];
                      }}
                      getOptionValue={(options) => {
                        return options["name"];
                      }}
                      value={formData.city}
                      name="city"
                      placeholder="City"
                      onChange={(e) =>
                        handleSelectChange({ value: e, name: "city" })
                      }
                    />{" "}
                    <span className="text-danger position-absolute top-50 end-0 mx-2 position-relative d-flex align-items-start flex-column">
                      *
                    </span>
                  </div>
                  <div
                    data-mdb-input-init
                    className="form-outline mb-2 position-relative d-flex align-items-start flex-column"
                  >
                    <label htmlFor="pinCode" className="text-start text-white">
                      PinCode :
                    </label>
                    <input
                      placeholder="Pin Code"
                      name="pinCode"
                      className="form-control"
                      id="pinCode"
                      value={formData?.pinCode}
                      onChange={handleInputChange}
                      style={{
                        background: "#F5F5F5",
                      }}
                      rows="4"
                    />{" "}
                    <span className="text-danger position-absolute top-50 end-0 mx-2">
                      *
                    </span>
                  </div>
                  {/* Submit button */}
                  {EventSingle_final?.eventsSchedule[0]?.isPaid &&
                  EventSingle_final?.eventsSchedule[0]?.selling_price_rupee >=
                    0 ? (
                    <>
                      <button
                        type="submit"
                        data-bs-toggle={`${
                          isFormValid === true && user && eventPurchased == null
                            ? "modal"
                            : ""
                        }`}
                        data-bs-target="#staticBackdrop"
                        className="btn btn-lg  text-white fw-bold formEventButton"
                        onClick={handleAuthentication}
                        style={{
                          borderRadius: "30px",
                          position: "relative",
                          top: "-15px",
                          right: "-90px",
                          backgroundColor: "#b767a2",
                        }}
                      >
                        {sellingPrice > 0 ? renderPrice() : "SUBMIT (FREE)"}
                      </button>
                      {isFormValid ? (
                        <OrderModal
                          formDataToSend={{ ...formData, userid: user?._id }}
                          scheduleid={EventSingle_final?.eventsSchedule[0]._id}
                          orderType={orderType}
                          product={EventSingle_final?.eventsSchedule[0] || ""}
                          navigate={true}
                          isExpired={false}
                          eventParent={eventparent}
                          eventsdata={formDataToSendevent}
                        />
                      ) : (
                        ""
                      )}
                    </>
                  ) : (
                    <button
                      type="submit"
                      className="btn btn-lg  text-white fw-bold formEventButton"
                      style={{
                        borderRadius: "30px",
                        position: "relative",
                        top: "-33px",
                        right: "-90px",
                        padding: "10px 35px",
                        backgroundColor: "#b767a2",
                      }}
                    >
                      {isLoading ? (
                        <div
                          className="spinner-border text-light"
                          role="status"
                        >
                          <span className="sr-only"></span>
                        </div>
                      ) : (
                        "SUBMIT"
                      )}
                    </button>
                  )}
                </form>
              </div>
            </div>
          </div>
        </section>
      )}

      {/* Join the Event Ended Here */}
      {EventSingle_final?.eventsgallerylist?.length > 0 && (
        <section>
          <div className="text-center singleevntsres">
            <h1 className="py-3 haedingfont" style={{ fontFamily: "myFont" }}>
              Gallery
            </h1>
          </div>
          <div className="container my-6">
            <div className="row position-relative">
              <div className="col-lg-6 col-sm-6 col-12">
                <div
                  className="large-image-container"
                  style={{ borderRadius: "8px" }}
                >
                  <Gallery
                    photos={[{ src: largeImage, width: 500, height: 650 }]}
                    onClick={() => openGalleryModal(largeImage)}
                  />
                  <ModalGateway>
                    {isGalleryModalOpen && (
                      <Modal onClose={closeGalleryModal}>
                        {/* Modal content */}
                        <div style={{ position: "relative" }}>
                          <img
                            className="galleryResponsvieModalImage"
                            src={selectedImage}
                            alt="Dr. Archika Didi"
                            style={{
                              width: 750,
                              borderRadius: "16px",
                              aspectRatio: 16 / 9,
                            }}
                          />
                          {/* Close button */}
                          <span
                            className="close-button"
                            onClick={closeGalleryModal}
                          >
                            <i className="fa-solid fa-circle-xmark"></i>
                          </span>
                          {/* Navigation buttons */}
                          {selectedImageIndex > 0 && (
                            <button
                              className="prev-button"
                              onClick={handlePrev}
                              disabled={selectedImageIndex === 0}
                            >
                              <i
                                className="fa-solid fa-chevron-left"
                                style={{
                                  position: "absolute",
                                  top: "46%",
                                  left: "10px",
                                  fontSize: "18px",
                                  color: "white",
                                }}
                              ></i>
                            </button>
                          )}
                          {selectedImageIndex <
                            EventSingle_final?.eventsgallerylist?.length -
                              1 && (
                            <button
                              className="next-button"
                              onClick={handleNext}
                              disabled={
                                selectedImageIndex ===
                                EventSingle_final?.eventsgallerylist.length - 1
                              }
                            >
                              <i
                                className="fa-solid fa-chevron-right"
                                style={{
                                  position: "absolute",
                                  top: "46%",
                                  right: "10px",
                                  fontSize: "18px",
                                  color: "white",
                                }}
                              ></i>
                            </button>
                          )}
                        </div>
                      </Modal>
                    )}
                  </ModalGateway>
                </div>
              </div>
              <div className="col-lg-6 col-sm-6 col-12">
                <div className="row ">
                  {smallImages.map((item, index) => (
                    <div key={index} className="col-lg-6 col-sm-6 col-12">
                      <div
                        className="gallery-wrapper my-2"
                        onClick={() => openGalleryModal(item?.thumbnail_image)}
                        onMouseEnter={() =>
                          handleHoverThumbnail(item?.thumbnail_image)
                        }
                      >
                        {item.status === true ? (
                          <img
                            src={item?.thumbnail_image}
                            alt="Dr. Archika Didi"
                            className="gallery-image"
                            style={{
                              overflow: "hidden",
                              cursor: "pointer",
                              borderRadius: "8px",
                            }}
                          />
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  ))}
                </div>
                <div className="mt-3 ">
                  {currentPage > 4 && (
                    <button className=" me-2">
                      <i
                        className="fa-solid fa-circle-chevron-left text-secondary left-gallery-icon"
                        onClick={handlePrevious}
                      ></i>
                    </button>
                  )}
                  {EventSingle_final?.eventsgallerylist.length >
                    displayedIndex.end && (
                    <buttton className=" ">
                      <i
                        className="fa-solid fa-circle-chevron-right text-secondary right-gallery-icon"
                        onClick={handleLoadMore}
                      ></i>
                    </buttton>
                  )}
                </div>
              </div>
            </div>
          </div>
        </section>
      )}

      {EventSingle_final?.eventsTestimonials.length > 0 ? (
        <div
          className="container-fluid text-center my-5 "
          style={{
            backgroundImage: `url(${specialPeople})`,
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center center",
            height: "10%",
          }}
        >
          <h1
            className="pt-5"
            style={{ fontSize: "3.5rem", fontFamily: "Playfair Display" }}
          >
            What People Say?
          </h1>

          <div className="container artvid my-5">
            <div className="row mb-5 d-flex justify-content-center mt-5">
              <Slider
                ref={(slider) => {
                  sliderRef1 = slider;
                }}
                {...settings1}
              >
                {EventSingle_final?.eventsTestimonials.map(
                  (testimonialsItem, index) => (
                    <section
                      className="col-md-6 col-sm-12 col-lg-3"
                      style={{ maxWidth: "22rem" }}
                    >
                      <div
                        className="card"
                        style={{ borderRadius: "15px", margin: "0 8px" }}
                        key={index}
                      >
                        <div className="card-body">
                          <blockquote className="blockquote blockquote-custom bg-white px-3 pt-1 text-left">
                            <p className="">
                              <Moment
                                className="fw-semibold"
                                format="DD/MMM/YYYY"
                                withTitle
                              >
                                {testimonialsItem?.created_at}
                              </Moment>
                            </p>
                            <p className="mb-0 mt-1 font-italic">
                              {testimonialsItem?.description}
                            </p>
                            <div className="blockquote-custom-icon shadow-1-strong"></div>
                          </blockquote>
                        </div>
                      </div>
                      <div
                        className="card-body d-flex flex-row mt-4 justify-content-left my-5"
                        style={{ textAlign: "left" }}
                      >
                        <img
                          src={testimonialsItem?.user_image}
                          className="rounded-circle me-3"
                          height="60px"
                          width="60px"
                          alt="Dr. Archika Didi"
                        />
                        <div>
                          <h5 className="card-title font-weight-bold">
                            {testimonialsItem?.user_name}
                          </h5>
                        </div>
                      </div>
                    </section>
                  )
                )}
              </Slider>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}

      {allEventBlogs?.length > 0 && (
        <>
          <section>
            <div className="cotainer-fluid mt-5 ">
              <div className=" d-flex align-items-end justify-content-center ">
                <h1
                  className="mt-5 haedingfont"
                  style={{ fontFamily: "myFont" }}
                >
                  Latest Blog News
                </h1>
              </div>
            </div>
          </section>
          <section>
            <div className="container-fluid beloved pt-5 ">
              <div className="container">
                <div className="row mb-5 d-flex justify-content-center ">
                  <Slider
                    ref={(slider) => {
                      sliderRef1 = slider;
                    }}
                    {...settings1}
                  >
                    {Array.isArray(allEventBlogs) &&
                      allEventBlogs
                        .filter((item) => item.forEvent == true)
                        .map((element, innerIndex) => (
                          <div
                            key={`${innerIndex}-${innerIndex}`}
                            className="col-md-6 col-sm-12 col-lg-4 pb-3"
                            style={{ cursor: "pointer" }}
                            onClick={() => annualsingleEvents(element?.slug)}
                          >
                            <div
                              className="card item-card text-dark mx-2 card_height1"
                              style={{
                                borderRadius: "10px",
                              }}
                            >
                              <img
                                src={element?.thumbnail_image}
                                alt="Dr. Archika Didi"
                                style={{
                                  borderRadius: "10px 10px 0px 0px",
                                  aspectRatio: "3/2",
                                }}
                              />
                              <div className="container">
                                <h5
                                  className="mt-1  text-capitalize"
                                  style={{
                                    fontFamily: "lato",
                                    color: "black",
                                  }}
                                >
                                  {element?.title?.length > titlemaxLength
                                    ? `${element.title.substring(
                                        0,
                                        titlemaxLength
                                      )}...`
                                    : element?.title}
                                </h5>
                                <p
                                  className="card-text lato text-capitalize pb-3"
                                  dangerouslySetInnerHTML={{
                                    __html:
                                      element?.description?.length > maxLength
                                        ? `${element.description.substring(
                                            0,
                                            maxLength
                                          )}...`
                                        : element?.description,
                                  }}
                                  style={{
                                    fontFamily: "Lato",
                                    color: "#5B5B5B",
                                  }}
                                ></p>
                              </div>
                            </div>
                          </div>
                        ))}
                  </Slider>
                </div>
              </div>
            </div>
          </section>
        </>
      )}
    </>
  );
};

export default Shivratri;

import React from "react";
import { useState, useEffect, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import MetaData from "../component/layout/MetaData";
import SmallLoader from "./SmallLoader";
import Slider from "react-slick";
import EventBlogCards from './EventBlogCards'
import Moment from "react-moment";
import EventCards from "./EventCards";
import { getEventConfig, updateTimeLine } from "../Allslices/EventConfigSlice";
import { getEventList } from "../Allslices/EventConfigSlice";
import { useSelector, useDispatch } from "react-redux";
import "react-big-calendar/lib/css/react-big-calendar.css";
import crownimg from "../../src/images/crown.png";
import { getAllBlogs } from "../Allslices/blogSlice";

function Events() {
  const { eventType } = useParams();

  const { EventAllList } = useSelector((state) => state?.Event_Config);
  const { blog } = useSelector((state) => state?.blog);
  const [displayedEvents, setDisplayedEvents] = useState(3);
  const eventsPerPage = 3;
  const allEventBlogs=blog?.allEventBlogs
  const loadMoreEvents = () => {
    setDisplayedEvents((prev) => prev + eventsPerPage);
  };

  const maxLength2 = 300;

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getEventConfig());
    dispatch(getEventList(eventType));
    dispatch(getAllBlogs({ forEvent: true}));
    dispatch(updateTimeLine([]));
  }, []);

  const [fadeIn, setFadeIn] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const isFadeIn = window.scrollY > 70;
      setFadeIn(isFadeIn);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const [isHovering, setIsHovering] = useState(false);

  const handleMouseOver = () => {
    setIsHovering(true);
  };

  const handleMouseOut = () => {
    setIsHovering(false);
  };

  const Navigate = useNavigate();

  const titlemaxLength = 40;
  const maxLength = 180;
  const locationmaxLength = 35;
  const settings1 = {
    dots: true,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 6000,
    speed: 700,
    slidesToShow: 4,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 3,

          slidesToScroll: 3,
        },
      },
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 4,
        },
      },
    ],
  };

  let sliderRef1 = useRef();
  let sliderRef2 = useRef();

  const numCards = EventAllList?.allupcomingEvents?.length || 0;
  const minSlidesToShow = Math.min(3, numCards);
  const settings2 = {
    dots: true,
    infinite: true,
    autoplay: true,
    autoplayspeed: 6000,
    speed: 700,
    slidesToShow: minSlidesToShow,
    slidesToScroll: 3,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  settings2.slidesToShow = minSlidesToShow;
  let gridColumnClass = "col-md-4 col-sm-12 col-lg-3";
  if (minSlidesToShow === 3) {
    gridColumnClass = "col-md-4 col-sm-12 col-lg-3";
  } else if (minSlidesToShow === 2) {
    gridColumnClass = "col-md-3 col-sm-12 col-lg-3";
  } else if (minSlidesToShow === 1) {
    gridColumnClass = "col-md-3 col-sm-12 col-lg-3";
  }
  const singleevent = (slug, scheduleslug) => {
    Navigate(`/events/eventdetails/${slug}/${scheduleslug}`);
  };
  const annualsingleEvents = (slug) => {
    Navigate(`/blog/${slug}`);
  };
  return (
    <>
      <MetaData
        title={EventAllList?.config?.eventsbanner?.meta_title ?? "Dr. Archika Didi"}
        description={EventAllList?.config?.eventsbanner?.meta_description ?? "Dr. Archika Didi"}
        tags={EventAllList?.config?.eventsbanner?.meta_tag ?? "Dr. Archika Didi"}
      />
      {EventAllList ? (
        <>
          <section className="overflow-hidden">
            <div className="desktop-only">
              <div className="position-relative d-flex">
                <img
                  loading="lazy"
                  className="w-100"
                  src={`${EventAllList?.config?.eventsbanner?.background_image}`}
                  alt="Dr. Archika Didi"
                />
                <div className="position-absolute w-100 h-100 top-0">
                  <div className="container h-100">
                    <div className="row h-100 align-items-center">
                      {EventAllList?.config?.eventsbanner?.alignItem ===
                        "left" && (
                        <>
                          <div className="col-lg-6 col-md-6 col-sm-6 col-6 d-flex flex-column align-items-start justify-content-center">
                            <div>
                              <h1
                                className="text-dark d-flex align-items-end haedingfont"
                                style={{
                                  fontFamily: "myFont",
                                  color: "rgba(255, 255, 255, 1)",
                                  fontWeight: 400,
                                }}
                                onMouseOver={handleMouseOver}
                                onMouseOut={handleMouseOut}
                              >
                                {EventAllList?.config?.eventsbanner?.heading}
                              </h1>

                              <p
                                className=" eventsubheading text-dark homebdes mt-4"
                                style={{
                                  fontFamily: "lato",
                                  textAlign: "justify",
                                }}
                              >
                                {EventAllList?.config?.eventsbanner?.subheading}
                              </p>
                            </div>
                          </div>
                          <div className="col-lg-6 col-md-6 col-sm-6 col-12"></div>
                        </>
                      )}
                      {EventAllList?.config?.eventsbanner?.alignItem ===
                        "right" && (
                        <>
                          <div className="col-lg-6 col-md-6 col-sm-6 col-12"></div>

                          <div className="col-lg-6 col-md-6 col-sm-6 col-6 d-flex flex-column align-items-start justify-content-center">
                            <div>
                              <h4
                                className="text-white d-flex align-items-end justify-content-center haedingfont"
                                style={{
                                  fontFamily: "myFont",
                                  color: "rgba(255, 255, 255, 1)",
                                  marginTop: "200px",
                                }}
                              >
                                {EventAllList?.config?.eventsbanner?.heading}
                              </h4>

                              <h6
                                className=" eventsubheading text-dark homebdes mt-4"
                                style={{
                                  fontFamily: "Playfair Display",
                                  fontSize: "18px",
                                  textAlign: "justify",
                                }}
                              >
                                {EventAllList?.config?.eventsbanner?.subheading}
                              </h6>
                            </div>
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="mobile-only">
              <div
                className="container-fluid mobile-only"
                style={{ position: "relative", display: "flex" }}
              >
                <img
                  loading="lazy"
                  src={EventAllList?.config?.eventsbanner?.background_image}
                  alt="Dr. Archika Didi"
                />
                <div
                  className="container"
                  style={{ position: "absolute", height: "100%" }}
                >
                  <div className="row" style={{ height: "100%" }}>
                    <div className="col-6 d-flex justify-content-start align-items-center">
                      {EventAllList?.config?.eventsbanner?.alignItem ===
                        "left" && (
                        <>
                          <p
                            className="homebannertitle"
                            style={{ fontFamily: "myFont", fontSize: "24px" }}
                            dangerouslySetInnerHTML={{
                              __html:
                                EventAllList?.config?.eventsbanner?.res_heading,
                            }}
                          ></p>
                        </>
                      )}
                    </div>
                    <div className="col-6 d-flex justify-content-start align-items-center">
                      {EventAllList?.config?.eventsbanner?.alignItem ===
                        "right" && (
                        <>
                          <p
                            className="homebannertitle"
                            style={{ fontFamily: "myFont", fontSize: "24px" }}
                            dangerouslySetInnerHTML={{
                              __html:
                                EventAllList?.config?.eventsbanner?.res_heading,
                            }}
                          ></p>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>

              <div className="container">
                <p
                  className="py-4 "
                  style={{
                    fontFamily: "Lato",
                    textAlign: "justify",
                  }}
                  dangerouslySetInnerHTML={{
                    __html: EventAllList?.config?.eventsbanner?.res_subheading,
                  }}
                ></p>
              </div>

              {/* banner ends here */}
            </div>
          </section>
        </>
      ) : (
        <SmallLoader />
      )}

      {EventAllList?.alleventsFinal.length > 0 && (
        <section className="eventsres">
          <h3
            className="text-center pt-3 haedingfont"
            style={{
              fontFamily: "myFont",
              color: "rgba(0, 0, 0, 1)",
            }}
          >
            Upcoming Events
          </h3>
          <div className="container-fluid beloved pt-5">
            <div className="container">
              <div
                className={`row mb-5 d-flex justify-content-center ${
                  EventAllList?.allupcomingEvents?.length < 2
                    ? "sliderscontainerevent"
                    : ""
                }`}
              >
                <Slider
                  ref={(slider) => {
                    sliderRef2 = slider;
                  }}
                  {...settings2}
                >
                  {Array.isArray(EventAllList?.allupcomingEvents) &&
                    EventAllList?.allupcomingEvents.map(
                      (element, innerIndex) => (
                        
                        <EventCards
                        key={element.id}
                        event={element}
                        onClick={singleevent}
                        />
                      )
                    )}
                </Slider>
              </div>
            </div>
          </div>
        </section>
      )}

      {/* )} */}
      {EventAllList?.alleventsFinal.length > 0 && (
        <section>
          <div
            className="container-fluid eventsres"
            style={{
              background:
                "linear-gradient(180deg, #E5F0FB 32%, rgba(229, 240, 251, 0) 100%)",
            }}
          >
            <div className="py-5 haedingfont">
              <h3
                className="text-center haedingfont"
                style={{
                  fontFamily: "myfont",
                  color: "rgba(0, 0, 0, 1)",
                }}
              >
                List Of All Events
              </h3>
            </div>
            <div className="container">
              <div className="row">
                {Array.isArray(EventAllList?.alleventsFinal) &&
                  EventAllList?.alleventsFinal
                    .slice(0, displayedEvents)
                    .map((element, innerIndex) => (
                      <div
                        key={`${innerIndex}-${innerIndex}`}
                        className="col-md-6 col-sm-12 col-lg-12 mb-2 pb-3"
                      >
                        <div
                          className="card item-card text-dark"
                          onClick={() =>
                            singleevent(
                              element.slug,
                              element?.eventsSchedule[0]?.scheduleslug
                            )
                          }
                        >
                          <div className="row">
                            <div className="col-sm-12 col-lg-3 d-block align-content-center">
                              <img
                                src={
                                  element?.eventsSchedule[0]?.thumbnail_image
                                }
                                alt="Dr. Archika Didi"
                                style={{
                                  aspectRatio: "3/2",
                                }}
                              />
                            </div>
                            <div className="col-sm-12 card-body col-lg-9 px-4">
                              <h5
                                className="mt-1  text-capitalize"
                                style={{
                                  fontFamily: "lato",
                                  color: "black",
                                }}
                              >
                                {element?.title}
                              </h5>
                              <p
                                className="card-text text-capitalize"
                                style={{
                                  color: "#B767A2",
                                  fontWeight: 700,
                                  fontFamily: "Lato",
                                }}
                              >
                                <span>
                                  <Moment
                                    className="fw-semibold"
                                    format="D MMM YYYY HH:mm "
                                    withTitle
                                  >
                                    {element?.eventsSchedule[0]?.start_Date}
                                  </Moment>
                                </span>
                                <span>-</span>
                                <span>
                                  <Moment
                                    className="fw-semibold"
                                    format="D MMM YYYY HH:mm "
                                    withTitle
                                  >
                                    {element?.eventsSchedule[0]?.end_Date}
                                  </Moment>
                                </span>
                              </p>
                              <p
                                className="card-text text-capitalize"
                                style={{ fontFamily: "Lato", color: "#5B5B5B" }}
                                dangerouslySetInnerHTML={{
                                  __html:
                                    element?.eventsSchedule[0]?.shortdescription
                                      ?.length > maxLength2
                                      ? `${element?.eventsSchedule[0].shortdescription.substring(
                                          0,
                                          maxLength2
                                        )}...`
                                      : element?.eventsSchedule[0]
                                          ?.shortdescription,
                                }}
                              ></p>
                              <p
                                className=" card-texttext-capitalize"
                                style={{ fontFamily: "Lato", color: "black" }}
                              >
                                {element?.eventsSchedule[0].eventType ==
                                "Offline" ? (
                                  <>
                                    <i className="fa-solid fa-location-dot text-capitalize"></i>{" "}
                                    {element?.eventsSchedule[0]?.location}
                                  </>
                                ) : (
                                  ""
                                )}
                              </p>
                            </div>
                          </div>
                          {element?.isPaid ? (
                            <img
                              src={crownimg}
                              style={{
                                top: "1.2rem",
                                right: "1.7rem",
                                width: "1.5rem",
                              }}
                              className="img-fluid  position-absolute"
                              alt="Dr. Archika Didi"
                            />
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                    ))}
              </div>
              <div className="mt-5 text-center">
                {EventAllList?.alleventsFinal &&
                  EventAllList?.alleventsFinal.length > displayedEvents && (
                    <button
                      className="btn btn-lg text-light rounded cmn-t-shake py-3 px-5 rounded-pill shadowbuttons"
                      onClick={loadMoreEvents}
                    >
                      LOAD MORE
                    </button>
                  )}
              </div>
            </div>
          </div>
        </section>
      )}

      {allEventBlogs?.length > 0 && (
        <>
          <section>
            <div className="cotainer-fluid mt-5 ">
              <div className=" d-flex align-items-end justify-content-center ">
                <h1
                  className="mt-5 haedingfont"
                  style={{ fontFamily: "myFont" }}
                >
                  Latest Blog News
                </h1>
              </div>
            </div>
          </section>
          <section>
            <div className="container-fluid beloved pt-5 ">
              <div className="container">
                <div className="row mb-5 d-flex justify-content-center  ">
                  <Slider
                    ref={(slider) => {
                      sliderRef1 = slider;
                    }}
                    {...settings1}
                  >
                    {Array.isArray(allEventBlogs) &&
                      allEventBlogs
                        .filter((item) => item.forEvent == true)
                        .map((element, innerIndex) => (
                          
                          <EventBlogCards
                          key={element.id} 
                          allEventBlogs={element}
                          onClick={annualsingleEvents}

                           />
                        ))}
                  </Slider>
                </div>
              </div>
            </div>
          </section>
        </>
      )}

    </>
  );
}

export default Events;
